import { RouteRecordRaw } from 'vue-router';

export const utlilitiesRoutes: Array<RouteRecordRaw> = [
  {
    path: '/utilities',
    name: 'AllMeters',
    component: () => import('./all-meters.vue')
  },
  {
      path: '/read-meter',
      name: 'ReadMeter',
      component: () => import('./send-meter-data.vue')
  }
];