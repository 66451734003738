import { RouteRecordRaw } from 'vue-router';

export const issuesRoutes: Array<RouteRecordRaw> = [
  {
    path: '/issues',
    name: 'AllIssues',
    component: () => import('./all-issues.vue')
  },
  {
      path: '/create-issue',
      name: 'SendIssueData',
      component: () => import('./send-issue-data.vue')
  }
];